import React from "react";
import "./HeaderStyles.scss";
import MenuToggle from "./MenuToggle";
import { Link } from "react-router-dom";

const Header = ({ toggleNav }) => {
  return (
    <header className='header'>
      <MenuToggle onClick={toggleNav} />
      <Link className='header__link' to='/'>
        <h1>LurrArtean</h1>
      </Link>
    </header>
  );
};

export default Header;
