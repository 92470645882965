import React, { useState, useEffect } from "react";
import "./HomeStyles.scss";
import { getHomepage } from "../../contentful";
import marked from "marked";

const Home = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    window.scroll(0, 0);
    getHomepage().then((data) => setContent(data[0].fields));
  }, []);
  return (
    <div className='page home-page'>
      {content.image && (
        <div className='home-page__image'>
          <img src={content?.image.fields.file.url} />
        </div>
      )}

      {content.text && (
        <section
          className='home-page__text'
          dangerouslySetInnerHTML={{ __html: marked(content.text) }}
        />
      )}
    </div>
  );
};

export default Home;
