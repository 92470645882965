import React from "react";
import "./HeaderStyles.scss";

const MenuToggle = ({ onClick }) => {
  return (
    <button onClick={onClick} className='menu-toggle'>
      <div className='menu-toggle__line' />
      <div className='menu-toggle__line' />
      <div className='menu-toggle__line' />
    </button>
  );
};

export default MenuToggle;
