const client = require("contentful").createClient({
  space: "2889nxq2d3tq",
  accessToken: "fk82_abIoNW-f0foWcdxq0lOop2VzVV7eEkwjHA91GY",
});

const getProjects = () =>
  client
    .getEntries({ content_type: "project", order: "fields.order" })
    .then((response) => response.items);

const getProject = (title) =>
  client
    .getEntries({ content_type: "project", "fields.title": title })
    .then((response) => response.items);

const getHomepage = (title) =>
  client
    .getEntries({ content_type: "homepage" })
    .then((response) => response.items);

const getBiogPage = () =>
  client
    .getEntries({ content_type: "biogPage" })
    .then((response) => response.items);

export { getProjects, getHomepage, getProject, getBiogPage };
