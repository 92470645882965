import React, { useState, useEffect } from "react";
import "./App.css";
import "./globalStyles.scss";
import Slider from "./Slider";
import Tabbable from "./Tabbable";
import Header from "./components/Header/Header";
import Nav from "./components/Nav/Nav";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Footer from "./components/Footer/Footer";
import Project from "./pages/project/Project";
import Contact from "./pages/contact/Contact";
import Biog from "./pages/biog/Biog";

const App = () => {
  const [navOpen, setNavOpen] = useState(false);
  return (
    <div className='wrapper'>
      <Header
        toggleNav={() => {
          window.scrollTo(0, 0);
          setNavOpen(!navOpen);
        }}
      />
      <div className='app-container'>
        <Nav navOpen={navOpen} toggleNav={() => setNavOpen(!navOpen)} />
        <div className='page-container'>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/projects/:title' exact component={Project} />
            <Route path='/kontaktua' exact component={Contact} />
            <Route path='/bio' exact component={Biog} />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
