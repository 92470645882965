import React, { useRef, useEffect, useState } from "react";
import "./CarouselStyles.scss";
import CarouselItem from "./CarouselItem";

const Carousel = ({ items, pdfViewer }) => {
  const carouselItems = useRef([]);
  const carouselRef = useRef();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const snapTo = (dir) => {
    if (carouselRef.current) {
      if (dir === "next" && selectedIndex < items.length - 1) {
        carouselRef.current.scrollTo({
          behavior: "smooth",
          left: carouselItems.current[selectedIndex + 1].offsetLeft,
        });
        setSelectedIndex(selectedIndex + 1);
      }
      if (dir === "prev" && selectedIndex > 0) {
        carouselRef.current.scrollTo({
          behavior: "smooth",
          left: carouselItems.current[selectedIndex - 1].offsetLeft,
        });
        setSelectedIndex(selectedIndex - 1);
      }
    }
  };

  const snapToSlide = (index) => {
    setSelectedIndex(index);
    carouselRef.current.scrollTo({
      behavior: "smooth",
      left: carouselItems.current[index].offsetLeft,
    });
  };

  return (
    <div>
      <ul ref={carouselRef} className={"carousel"}>
        {items?.map((item, index) => (
          <li
            className={
              index !== selectedIndex ? "slide carousel__item--side" : "slide"
            }
            ref={(item) => {
              if (carouselItems.current && item) {
                return (carouselItems.current[index] = item);
              }
            }}
          >
            <CarouselItem
              pdfViewer={pdfViewer}
              item={item}
              setIndex={setSelectedIndex}
              index={index}
              snapToSlide={snapToSlide}
            />
          </li>
        ))}
      </ul>
      {items.length > 1 && (
        <div>
          {!pdfViewer && (
            <ul className='carousel__pagination'>
              {items?.map((i, index) => (
                <li key={index}>
                  <button
                    className='carousel__pagination__button'
                    style={{
                      color: index === selectedIndex ? "black" : "grey",
                    }}
                    onClick={() => snapToSlide(index)}
                  >
                    o
                  </button>
                </li>
              ))}
            </ul>
          )}
          {/* <div className='carousel__chevrons'>
            {!pdfViewer && (
              <button onClick={() => snapTo("prev")}>{"<"}</button>
            )}
            {pdfViewer && (
              <span>
                {selectedIndex + 1} / {items.length}
              </span>
            )}
            {!pdfViewer && (
              <button onClick={() => snapTo("next")}>{">"}</button>
            )}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Carousel;
