import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
} from "react";
import Slide from "./Slide";

const carouselStyles = {
  all: "initial",
  width: "100%",
  display: "inline-flex",
};

const wrapperStyles = {
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "100%",
};

function Slider({
  children,
  onSlideComplete,
  onSlideStart,
  threshHold = 100,
  transition = 0.5,
}) {
  // Adam code
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  // const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef();

  const dragging = useRef(false);
  const startPos = useRef(0);
  const currentTranslate = useRef(0);
  const prevTranslate = useRef(0);
  const currentIndex = useRef(activeIndex || 0);
  const sliderRef = useRef("slider");
  const animationRef = useRef(null);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
    return window.removeEventListener("resize", () => {});
  }, window.innerWidth);

  const setPositionByIndex = useCallback(
    (w = slideWidth) => {
      currentTranslate.current = currentIndex.current * -w;
      prevTranslate.current = currentTranslate.current;
      console.log(currentTranslate.current);
      setSliderPosition();
    },
    [slideWidth]
  );

  useEffect(() => {
    imageLoaded && setWidth();
  }, [imageRef, imageLoaded]);

  const setWidth = () => {
    const width = imageRef.current.getBoundingClientRect().width;
    setSlideWidth(width);
  };

  function getPositionX(event) {
    return event.type.includes("mouse")
      ? event.pageX
      : event.touches[0].clientX;
  }

  function getElementDimensions(ref) {
    const width = slideWidth;
    console.log(width);
    const height = ref.current.clientHeight;
    return { width, height };
  }

  const transitionOn = () =>
    (sliderRef.current.style.transition = `transform ${transition}s ease-out`);

  const transitionOff = () => (sliderRef.current.style.transition = "none");

  // watch for a change in activeIndex prop
  useEffect(() => {
    if (activeIndex !== currentIndex.current) {
      transitionOn();
      currentIndex.current = activeIndex;
      setPositionByIndex();
    }
  }, [activeIndex, setPositionByIndex]);

  // set width after first render
  // set position by startIndex
  // no animation on startIndex
  useLayoutEffect(() => {
    // setDimensions(getElementDimensions(sliderRef));
    console.log(getElementDimensions(sliderRef).width);
    setPositionByIndex(getElementDimensions(sliderRef).width);
  }, [setPositionByIndex]);

  // add event listeners
  useEffect(() => {
    // set width if window resizes
    const handleResize = () => {
      transitionOff();
      const { width, height } = getElementDimensions(sliderRef);
      // setDimensions({ width, height });
      setPositionByIndex(width);
    };

    const handleKeyDown = ({ key }) => {
      const arrowsPressed = ["ArrowRight", "ArrowLeft"].includes(key);
      if (arrowsPressed) transitionOn();
      if (arrowsPressed && onSlideStart) {
        onSlideStart(currentIndex.current);
      }
      if (key === "ArrowRight" && currentIndex.current < children.length - 1) {
        currentIndex.current += 1;
      }
      if (key === "ArrowLeft" && currentIndex.current > 0) {
        currentIndex.current -= 1;
      }
      if (arrowsPressed && onSlideComplete)
        onSlideComplete(currentIndex.current);
      setPositionByIndex();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [children.length, setPositionByIndex, onSlideComplete, onSlideStart]);

  function touchStart(index) {
    return function (event) {
      // transitionOn();
      currentIndex.current = index;
      startPos.current = getPositionX(event);
      dragging.current = true;
      animationRef.current = requestAnimationFrame(animation);
    };
  }

  function touchMove(event) {
    if (dragging.current) {
      const currentPosition = getPositionX(event);
      currentTranslate.current =
        prevTranslate.current + currentPosition - startPos.current;
    }
  }

  function touchEnd() {
    // transitionOn();
    cancelAnimationFrame(animationRef.current);
    dragging.current = false;
    const movedBy = currentTranslate.current - prevTranslate.current;

    // if moved enough negative then snap to next slide if there is one
    if (movedBy < -threshHold && currentIndex.current < children.length - 1) {
      currentIndex.current += 1;
      setActiveIndex((index) => index++);
    }

    // if moved enough positive then snap to previous slide if there is one
    if (movedBy > threshHold && currentIndex.current > 0) {
      currentIndex.current -= 1;
      setActiveIndex((index) => index - 1);
    }

    // transitionOn();

    setPositionByIndex();
  }

  function animation() {
    setSliderPosition();
    if (dragging.current) requestAnimationFrame(animation);
  }

  function setSliderPosition() {
    sliderRef.current.style.transform = `translateX(${currentTranslate.current}px)`;
  }

  return (
    <div style={wrapperStyles}>
      <div style={carouselStyles} ref={sliderRef}>
        {children.map((child, index) => {
          const slideClasses =
            currentIndex.current !== index
              ? "slider-slide"
              : "slider-slide active";
          return (
            <div
              key={child.key}
              onTouchStart={touchStart(index)}
              onMouseDown={touchStart(index)}
              onTouchMove={touchMove}
              onMouseMove={touchMove}
              onTouchEnd={touchEnd}
              onMouseUp={touchEnd}
              onMouseLeave={() => {
                if (dragging.current) touchEnd();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div
                onDragStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }}
                className={slideClasses}
              >
                <button
                  className='btn leftBtn'
                  onClick={() => {
                    if (activeIndex > 0) setActiveIndex((index) => index - 1);
                  }}
                ></button>
                <button
                  className='btn rightBtn'
                  onClick={() => {
                    if (activeIndex < children.length - 1)
                      setActiveIndex((index) => index + 1);
                  }}
                ></button>
                <img
                  ref={imageRef}
                  onLoad={() => setImageLoaded(true)}
                  src={child?.src}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Slider;
