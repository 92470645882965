import React, { useEffect, useRef } from "react";

const CarouselItem = ({ setIndex, index, item, snapToSlide, pdfViewer }) => {
  const itemRef = useRef();

  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIndex(index);
      }
    });
  };

  const options = {
    rootMargin: "0px",
    threshold: 0.5,
  };

  const observer = new IntersectionObserver(callback, options);
  useEffect(() => {
    observer.observe(itemRef.current);
  }, [itemRef]);
  if (!item) return null;
  return (
    <div
      onClick={() => snapToSlide(index)}
      ref={itemRef}
      className={
        pdfViewer ? "carousel__item carousel__item--pdf" : "carousel__item"
      }
    >
      <div className='carousel__image-wrapper'>
        <img src={item.fields.file.url} />
      </div>
    </div>
  );
};

export default CarouselItem;
