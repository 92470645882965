import React, { useEffect } from "react";

const ViewHeightWrapper = ({ children }) => {
  const setViewHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  useEffect(() => {
    window.addEventListener("resize", setViewHeight);

    return () => window.removeEventListener("resize", setViewHeight);
  }, []);
  return <>{children}</>;
};

export default ViewHeightWrapper;
