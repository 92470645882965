import React, { useEffect } from "react";
import "./ContactStyles.scss";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className='page contact-page '>
      <h1>Kontaktua</h1>
      <a href='mailto:info@lurrartean.com'>info@lurrartean.com</a>
    </div>
  );
};

export default Contact;
