import React, { useEffect, useState } from "react";
import { getBiogPage } from "../../contentful";
import marked from "marked";
import "./BiogStyles.scss";

const Biog = () => {
  const [content, setContent] = useState({});
  useEffect(() => {
    getBiogPage().then((data) => setContent(data[0].fields));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (!content.image || !content.mau || !content.carmina) return null;

  const { image, mau, carmina } = content;
  return (
    <div className='page biog-page'>
      <div className='biog-page__image'>
        <img src={image.fields.file.url} />
      </div>
      <div className='biog-page__texts'>
        <section
          className='biog-page__texts__biog'
          dangerouslySetInnerHTML={{ __html: marked(mau) }}
        />
        <section
          className='biog-page__texts__biog'
          dangerouslySetInnerHTML={{ __html: marked(carmina) }}
        />
      </div>
    </div>
  );
};

export default Biog;
