import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./NavStyles.scss";
import { getProjects } from "../../contentful";

export const routes = [
  { title: "Bio", route: "bio" },
  { title: "Kontaktua", route: "kontaktua" },
];

const Nav = ({ navOpen, toggleNav }) => {
  const navRef = useRef();
  const [height, setHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [projects, setProjects] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getProjects().then((data) => setProjects(data));
  }, []);

  useEffect(() => {
    setItems([
      { title: "Hasiera", route: "" },
      ...projects.map((p) => {
        return {
          title: p.fields.title,
          route: p.fields.title,
          isProject: true,
        };
      }),
      ...routes,
    ]);
  }, [projects]);

  useEffect(() => {
    setIsMobile(window.innerWidth < 900);
    navRef.current && setHeight(navOpen ? navRef.current.scrollHeight : 0);
  }, [navOpen, navRef]);

  return (
    <>
      {!isMobile ? (
        <nav className='nav'>
          {items.map((r) => (
            <NavLink
              exact
              onClick={toggleNav}
              activeClassName='nav--active'
              className={
                !r.isProject ? "nav__item" : "nav__item nav__item--project"
              }
              to={r.isProject ? `/projects/${r.title}` : `/${r.route}`}
            >
              {r.title}
            </NavLink>
          ))}
        </nav>
      ) : (
        <nav style={{ height }} ref={navRef} className='nav'>
          {items.map((r) => (
            <NavLink
              exact
              onClick={toggleNav}
              activeClassName='nav--active'
              className={
                !r.isProject ? "nav__item" : "nav__item nav__item--project"
              }
              to={r.isProject ? `/projects/${r.title}` : `/${r.route}`}
            >
              {r.title}
            </NavLink>
          ))}
        </nav>
      )}
    </>
  );
};

export default Nav;
