import React from "react";

const Youtube = ({ code }) => {
  console.log(code.split("=")[1]);
  const param = code.split("=")[1];
  const url = `https://www.youtube.com/embed/${param}`;
  return (
    <iframe
      className='youtube'
      // width='560'
      // height='315'
      src={url}
      title='YouTube video player'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      // allowfullscreen
    ></iframe>
  );
};

export default Youtube;
